import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta: {
      title: "Book online | Last-minute appointments with barbershops, salons and wellness pros | OnDaQ",
      description : "Book online Last-minute appointments with Beauty Salon, Stylists, Barbershops and Wellness Pros | OnDaQ"
    }
  },
  {
    path: '/home',
    name: 'OnDaQHome',
    component: () => import('../views/Home.vue'),
    meta: {
      title: "Book online | Last-minute appointments with barbershops, salons and wellness pros | OnDaQ",
      description : "Book online Last-minute appointments with Beauty Salon, Stylists, Barbershops and Wellness Pros | OnDaQ"
    }
  },
  {
    path: '/zendesk/authenticate',
    name: 'ZendeskAuth',
    component: () => import('../views/zendesk/ZendeskAuth.vue'),
  },
  {
    path: '/zendesk/logout',
    name: 'ZendeskLogout',
    component: () => import('../views/zendesk/ZendeskLogout.vue'),
  },
  {
    path: '/listing/:location/:service?',
    name: 'Listing',
    component: () => import('../views/Search.vue'),
    meta: {
      title: "Search Listing - OnDaQ Online book appointment"
    }
  },
  // {
  //   path: '/profile/:slug',
  //   name: 'BusinessProfile',
  //   component: () => import('../views/BusinessProfile.vue')
  // },
  // {
  //   path: '/ondaq-business',
  //   name: 'OndaqBusiness',
  //   component: () => import('../views/OndaqBusiness.vue')
  // },
  {
    path: '/business',
    name: 'OndaqBusiness',
    component: () => import('../views/businessPage.vue'),
    meta: {
      title: "OnDaQ Business - OnDaQ Saloon Management System"
    }
  },
  {
    path: '/list-your-business',
    name: 'AddBusiness',
    component: () => import('../views/AddBusiness.vue'),
    meta: {
      title: "List your Business - OnDaQ Saloon Management System"
    }
  },
  {
    path: '/book-now/:slug/:service?',
    name: 'BookNow',
    component: () => import('../views/Booking.vue'),
    meta: {
      title: "Book Now - OnDaQ Saloon Management System"
    }
  },
  {
    path: '/checkout/:slug',
    name: 'Checkout',
    component: () => import('../views/Checkout.vue'),
    meta: {
      title: "Checkout - OnDaQ Saloon Management System"
    }
  },
  {
    path: '/booking-success/:slug/:booking_id',
    name: 'BookingSuccess',
    component: () => import('../views/BookingSuccess.vue'),
    meta: {
      title: "Booking Complete - OnDaQ Saloon Management System"
    }
  },
  {
    path: '/deal/:slug',
    name: 'DealDetail',
    component: () => import('../views/Deal_Detail.vue'),
    meta: {
      title: "Deal Detail - OnDaQ Saloon Management System"
    }
  },
  {
    path: '/deals/:location',
    name: 'AllDeals',
    component: () => import('../views/AllDeals.vue'),
    meta: {
      title: "All Deals - OnDaQ Saloon Management System"
    }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: {
      title: "About Us - OnDaQ Saloon Management System"
    }
  },
  {
    path: '/how-it-works',
    name: 'HowItWorks',
    component: () => import('../views/HowItWorks.vue'),
    meta: {
      title: "How it Works - OnDaQ Saloon Management System"
    }
  },
  {
    path: '/pricing',
    name: 'Pricing',
    component: () => import(/* webpackChunkName: "about" */ '../views/Pricing.vue'),
    meta: {
      title: "Pricing - OnDaQ Saloon Management System"
    }
  },
  {
    path: '/help-',
    name: 'HelpCenter',
    component: () => import('../views/Help.vue'),
    meta: {
      title: "Help Center - OnDaQ Saloon Management System"
    }
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: () => import('../views/PrivacyPolicy.vue'),
    meta: {
      title: "Privacy Policy - OnDaQ Saloon Management System"
    }
  },
  {
    path: '/terms-of-service',
    name: 'TermsofService',
    component: () => import('../views/TermsofService.vue'),
    meta: {
      title: "Terms of Service - OnDaQ Saloon Management System"
    }
  },
  {
      path: '/business',
      name: 'business',
      component: () => import('../views/dashboard/dashboardWrapper.vue'),
      children: [
          {
            path: '',
            name:'BusinessDashboard2',
            component: () => import('../views/dashboard/dashboard.vue'),
            meta: {
              pageTitle: "Dashboard"
            }
          },
          {
            path: 'dashboard',
            name:'BusinessDashboard',
            component: () => import('../views/dashboard/dashboard.vue'),
            meta: {
              pageTitle: "Dashboard"
            }
          },
          {
            path: 'business-profile',
            name:'editBusinessProfile',
            component: () => import('../views/dashboard/business/editBusinessProfile.vue'),
            meta: {
              pageTitle: "Business Profile"
            }
          },
          {
            path: 'services',
            name:'Services',
            component: () => import('../views/dashboard/services/list.vue'),
            meta: {
              pageTitle: "Services"
            }
          },
          {
            path: 'add-service',
            name:'addService',
            component: () => import('../views/dashboard/services/add-new.vue'),
            meta: {
              pageTitle: "Add Service"
            }
          },
          {
            path: 'edit-service/:id',
            name:'editService',
            component: () => import('../views/dashboard/services/edit.vue'),
            meta: {
              pageTitle: "Edit Service"
            }
          },
          {
            path: 'employees',
            name:'Employees',
            component: () => import('../views/dashboard/employees/employeesList.vue'),
            meta: {
              pageTitle: "Team"
            }
          },
          {
            path: 'add-employee',
            name:'addEmployee',
            component: () => import('../views/dashboard/employees/addEmployee.vue'),
            meta: {
              pageTitle: "Add a Team Member"
            }
          },
          {
            path: 'edit-employee/:id',
            name:'editEmployee',
            component: () => import('../views/dashboard/employees/editEmployee.vue'),
            meta: {
              pageTitle: "Edit Employee"
            }
          },
          {
            path: 'weekly-schedule',
            name:'weeklySchedule',
            component: () => import('../views/dashboard/business/weeklySchedule.vue'),
            meta: {
              pageTitle: "Weekly Schedule"
            }
          },
          {
            path: 'deals',
            name:'dealsList',
            component: () => import('../views/dashboard/deals/dealsList.vue'),
            meta: {
              pageTitle: "Business Deals"
            }
          },
          {
            path: 'add-new-deal',
            name:'addNewDeal',
            component: () => import('../views/dashboard/deals/addNewDeal.vue'),
            meta: {
              pageTitle: "Add Deal"
            }
          },
          {
            path: 'edit-deal/:slug',
            name:'editDeal',
            component: () => import('../views/dashboard/deals/editDeal.vue'),
            meta: {
              pageTitle: "Edit Deal"
            }
          },
          {
            path: 'messages/:id?/:type?',
            name:'messages',
            component: () => import('../views/dashboard/messages/messages.vue'),
            meta: {
              pageTitle: "Messages"
            }
          },
          {
            path: 'quotations',
            name:'quotations',
            component: () => import('../views/dashboard/raq/quotations.vue'),
            meta: {
              pageTitle: "Quotations"
            }
          },
          {
            path: 'transactions',
            name:'transactions',
            component: () => import('../views/dashboard/transactions/transactionsList.vue'),
            meta: {
              pageTitle: "Transactions"
            }
          },
          {
            path: 'calendar',
            name:'calendar',
            component: () => import('../views/dashboard/calendar/calendar.vue'),
            meta: {
              pageTitle: "Calendar"
            }
          },
          {
            path: 'add-walkin',
            name:'addWalkin',
            component: () => import('../views/dashboard/booking/addWalkIn.vue'),
            meta: {
              pageTitle: "Add WalkIn"
            }
          }
          ,
          {
            path: 'settings',
            name:'Settings',
            component: () => import('../views/dashboard/settings/Settings.vue'),
            meta: {
              pageTitle: "General Settings"
            }
          },
          {
            path: 'appointments/:type?',
            name:'appointments',
            component: () => import('../views/dashboard/booking/appointmentsList.vue'),
            meta: {
              pageTitle: "Appointments"
            }
          },
          {
            path: 'plan/:action',
            name:'updateSubscriptionPlan',
            component: () => import('../views/dashboard/subscriptionPlans/subscriptionPlans.vue'),
            meta: {
              pageTitle: "Subscription Plans"
            }
          },
          // {
          //   path: 'queues',
          //   name:'queues',
          //   component: () => import('../views/dashboard/booking/queueList.vue'),
          //   meta: {
          //     pageTitle: "Queues"
          //   }
          // },
          {
            path: 'campaigns-list',
            name: 'campaignsList',
            component: () => import('../views/dashboard/emails/emailCampaignsList.vue'),
            meta: {
              pageTitle: 'Email Campaigns List'
            }
          },
          // {
          //   path: '/campaign-list-generator',
          //   name: 'campaignListGenerator',
          //   component: () => import('../views/dashboard/emails/emailCampaignsListGenerator.vue'),
          //   meta: {
          //     pageTitle: 'List Generator'
          //   }
          // },
          // {
          //   path: '/edit-campaign-list/:id',
          //   name: 'editCampaignList',
          //   component: () => import('../views/dashboard/emails/editCampaignList.vue'),
          //   meta: {
          //     pageTitle: 'Edit Campaign List'
          //   }
          // },
          {
            path: 'campaigns',
            name: 'emailCampaigns',
            component: () => import('../views/dashboard/emails/emailCampaigns.vue'),
            meta: {
              pageTitle: 'Manage Campaigns'
            }
          },
          {
            path: 'add-new-campaign',
            name: 'addNewEmailCampaigns',
            component: () => import('../views/dashboard/emails/addNewEmailCampaigns.vue'),
            meta: {
              pageTitle: 'Add New Campaign'
            }
          },
          {
            path: 'templates',
            name: 'templates',
            component: () => import('../views/dashboard/emails/template.vue'),
            meta: {
              pageTitle: 'Email Templates'
            }
          },
          // {
          //   path: '/add-template',
          //   name: 'addTemplate',
          //   component: () => import('../views/dashboard/emails/addTemplate.vue'),
          //   meta: {
          //     pageTitle: 'Add Email Template'
          //   }
          // },
          {
            path: 'gallery',
            name:'gallery',
            component: () => import('../views/dashboard/gallery/gallery.vue'),
            meta: {
              pageTitle: "Gallery"
            }
          },
          {
            path: 'favourites',
            name:'bussFavourites',
            component: () => import('../views/dashboard/business/favourites.vue'),
            meta: {
              pageTitle: "Favourites"
            }
          },
          {
            path: 'stripe-reader',
            name:'stripeReader',
            component: () => import('../views/dashboard/stripe/stripeReader.vue'),
            meta: {
              pageTitle: "Terminal Reader"
            }
          },

          {
            path: 'stripe-connect/:code?/:state?',
            name:'stripeConnect',
            component: () => import('../views/dashboard/stripe/stripeConnect.vue'),
            meta: {
              pageTitle: "Connect Account"
            }
          },
          {
            path: 'stripe-pay-out',
            name:'stripePayOut',
            component: () => import('../views/dashboard/stripe/stripePayOut.vue'),
            meta: {
              pageTitle: "OnDaQ Payout"
            }
          },
          {
            path: 'payment-history',
            name:'stripePayment',
            component: () => import('../views/dashboard/stripe/stripePayment.vue'),
            meta: {
              pageTitle: "Booking Payment History"
            }
          }
      ]
  },
  {
    path: '/edit-profile',
    name: 'EditProfile',
    component: () => import('../views/userDashboard/EditProfile.vue'),
    meta: {
      title: "Edit Profile - OnDaQ Saloon Management System"
    }
  },
  {
    path: '/messages/:id?/:type?',
    name:'Chat',
    component: () => import('../views/userDashboard/Messages.vue'),
    meta: {
      title: "Chat - OnDaQ Saloon Management System"
    }
  },
  {
    path: '/settings',
    name:'UserSettings',
    component: () => import('../views/userDashboard/Settings.vue'),
    meta: {
      pageTitle: "Settings"
    }
  },
  {
    path: '/appointments/:type?',
    name:'userAppointments',
    component: () => import('../views/userDashboard/Appointments.vue'),
    meta: {
      title: "All Deals - OnDaQ Saloon Management System"
    }
  },
  {
    path: '/queue/:type?',
    name:'userQueue',
    component: () => import('../views/userDashboard/Queue.vue'),
    meta: {
      title: "User Queue - OnDaQ Saloon Management System"
    }
  },
  {
    path: '/favourites',
    name: 'userFavourites',
    component: () => import('../views/userDashboard/Favourites.vue'),
    meta: {
      title: "Favourite Users - OnDaQ Saloon Management System"
    }
  },
  {
    path: '/reviews',
    name: 'userReviews',
    component: () => import('../views/userDashboard/Reviews.vue'),
    meta: {
      title: "Reviews - OnDaQ Saloon Management System",
      requireAuth: false
    }
  },
  {
    path: '/:slug/:tab?',
    name: 'BusinessProfile',
    component: () => import('../views/BusinessProfile.vue'),
    meta: {
      title: "Online Booking | Last-minute appointments with Salons, Independent Beauty Professionals"
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('../views/NotFound.vue'),
    meta: {
      title: "Not Found - OnDaQ Saloon Management System"
    }
  }
]

const router = createRouter({
  //history: createWebHistory(process.env.BASE_URL),
  history: createWebHistory('/'),
  routes
})
router.beforeEach((to, from, next) => {
  // window.scrollTo(0, 0)
  document.title = to.meta.title || 'Book online | Last-minute appointments with barbershops, salons and wellness pros | OnDaQ'
  document.querySelector('meta[name="description"]').setAttribute('content', to.meta.description || 'Book online Last-minute appointments with Beauty Salon, Stylists, Barbershops and Wellness Pros | OnDaQ');
  next()
})
export default router
